export default function (
    category,
    menu,
    index,
    Availability,
    $log,
    $state,
    statePromise,
    availabilityLoggerService,
    $q,
    launchdarklyService,
    shouldUseUberEatsAvailability,
) {
    'ngInject';

    const vm = this;
    // TODO: REMOVE all-availability
    vm.shouldUseAllAvailability = launchdarklyService.allFlags
        ? launchdarklyService.allFlags['all-availability']
        : false;
    vm.shouldUseUberEatsAvailability = shouldUseUberEatsAvailability;
    vm.category = category;
    vm.menuName = menu.name;
    vm.selectedProduct = null;
    vm.isEveryProductAvailableByChannel = {
        webAvailability: true,
        kioskAvailability: true,
    };
    vm.activateAllWebProducts = true;
    vm.skuWarningData = {
        isDisplayed: false,
        invalidUbereatsProducts: {
            noSku: [],
            noMatchingSku: [],
        },
    };

    vm.$onInit = function () {
        updateAllChildrenButtonValue();
        vm.skuWarningData = processSkuWarningData();
    };

    vm.onChange = function (channel) {
        $log.info('AVAILABILITY-CATEGORY-EVENT', {
            categoryId: vm.category.categoryId,
        });
        Availability.updateCategory({
            actionId: vm.category.categoryId,
            availability: channel === 2 ? vm.category.webAvailability : vm.category.kioskAvailability,
            channel,
            menuId: vm.category.menuId,
        })
            .$promise.then(function (categoryUpdate) {
                availabilityLoggerService.log(
                    {
                        source: 'reception',
                        codeLocalizationLevel1: 'components/availability/category/category.controller.js',
                        codeLocalizationLevel2: 'vm.onChange(channel)',
                        codeLocalizationLevel3: 'Availability category reception event emitted',
                        channel,
                        categoryId: vm.category.categoryId,
                        menuId: vm.category.menuId,
                    },
                    vm.category.menuId,
                );
                category.webAvailability = categoryUpdate.webAvailability;
                category.kioskAvailability = categoryUpdate.kioskAvailability;
                menu.categories[index].webAvailability = categoryUpdate.webAvailability;
                menu.categories[index].kioskAvailability = categoryUpdate.kioskAvailability;
                updateAllChildrenButtonValue();
                update(channel);
            })
            .catch(function (error) {
                availabilityLoggerService.log(
                    {
                        source: 'reception',
                        codeLocalizationLevel1: 'components/availability/category/category.controller.js',
                        codeLocalizationLevel2: 'vm.onChange(channel)',
                        codeLocalizationLevel3: 'Availability category reception event failed',
                        channel,
                        error: JSON.stringify(error),
                        categoryId: vm.category.categoryId,
                        menuId: vm.category.menuId,
                    },
                    vm.category.menuId,
                );
                $state.go(
                    'home.availability',
                    {},
                    {
                        reload: 'home.availability',
                    },
                );
            });
    };

    // eslint-disable-next-line no-unused-vars
    vm.onChildUpdate = function (channel) {
        const property = channel === 2 ? 'webAvailability' : 'kioskAvailability';
        // eslint-disable-next-line no-shadow, no-unused-vars, @typescript-eslint/no-unused-vars
        const update = !vm.category.products
            .map(function (product) {
                return !product[property];
            })
            .every(function (availability) {
                return availability;
            });

        const hasAllChildrenAvailabilityFalse = vm.category.products.every((product) => product[property] === false);
        if (hasAllChildrenAvailabilityFalse) {
            updateCategoryToValue(channel, false);
        }

        updateAllChildrenButtonValue();

        // callback when a product availability is updated
        // here we should not activate or desactivate category availabilty => https://innovorder.atlassian.net/browse/ORDER-91
    };

    vm.updateAllChildrenProducts = (channel, value) => {
        const requestUpdateProductsAvailability = vm.category.products.map((product) => {
            return Availability.updateProduct({
                actionId: product.productId,
                availability: value,
                channel,
                menuId: product.menuId,
            }).$promise;
        });

        $q.all(requestUpdateProductsAvailability).then((updatedProducts) =>
            updateView({
                updatedProducts,
                channel,
                value,
            }),
        );
    };

    vm.openSkuWarningModal = () => {
        statePromise.go(
            'home.availability.modal_sku_warning',
            { skuWarningData: vm.skuWarningData },
            {
                location: false,
            },
            'skuWarning',
        );
    };

    const updateView = ({ updatedProducts, channel, value }) => {
        updateCategoryProducts(updatedProducts);
        updateCategoryToValue(channel, value);
    };

    const updateCategoryProducts = (updatedProducts) => {
        const updatedProductsHashMap = updatedProducts.reduce((map, updatedProduct) => {
            map[updatedProduct.productId] = updatedProduct;
            return map;
        }, {});

        vm.category.products = vm.category.products.map((product) => {
            const { webAvailability, kioskAvailability } = updatedProductsHashMap[product.productId];
            return {
                ...product,
                webAvailability,
                kioskAvailability,
            };
        });
    };

    const updateCategoryToValue = (channel, value) => {
        const channelAvailabilityKey = channel === 2 ? 'webAvailability' : 'kioskAvailability';
        vm.category[channelAvailabilityKey] = value;
        vm.onChange(channel);
    };

    const updateAllChildrenButtonValue = () => {
        vm.isEveryProductAvailableByChannel = {
            kioskAvailability: checkEveryProductAvailability(vm.category.products, 'kioskAvailability'),
            webAvailability: checkEveryProductAvailability(vm.category.products, 'webAvailability'),
        };
    };

    const checkEveryProductAvailability = (list, propertyToCheck) => {
        return list
            .map(function (product) {
                return product[propertyToCheck];
            })
            .every(function (availability) {
                return availability;
            });
    };

    const processSkuWarningData = () => {
        const initialAccumulator = {
            isDisplayed: false,
            invalidUbereatsProducts: {
                noSku: [],
                noMatchingSku: [],
            },
        };
        return vm.category.products.reduce((accumulator, product) => {
            if (!product.skuId) {
                return {
                    ...accumulator,
                    isDisplayed: true,
                    invalidUbereatsProducts: {
                        ...accumulator.invalidUbereatsProducts,
                        noSku: [...accumulator.invalidUbereatsProducts.noSku, product],
                    },
                };
            }

            if (product.uberEatsAvailability === undefined || product.uberEatsAvailability === null) {
                return {
                    ...accumulator,
                    isDisplayed: true,
                    invalidUbereatsProducts: {
                        ...accumulator.invalidUbereatsProducts,
                        noMatchingSku: [...accumulator.invalidUbereatsProducts.noMatchingSku, product],
                    },
                };
            }

            return accumulator;
        }, initialAccumulator);
    };

    function update(channel) {
        if (category.parentCategoryId) {
            const categories = [];
            for (let i = 0; i < menu.categories.length; i++) {
                if (category.parentCategoryId === menu.categories[i].parentCategoryId) {
                    categories.push(menu.categories[i]);
                }
            }
            const property = channel === 2 ? 'webAvailability' : 'kioskAvailability';
            // eslint-disable-next-line no-shadow
            const update = !categories
                // eslint-disable-next-line no-shadow
                .map(function (category) {
                    return !category[property];
                })
                .every(function (availability) {
                    return availability;
                });
            Availability.updateCategory({
                actionId: category.parentCategoryId,
                availability: update,
                channel,
                menuId: vm.category.menuId,
                // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            }).$promise.catch(function (error) {
                $state.go(
                    'home.availability',
                    {},
                    {
                        reload: 'home.availability',
                    },
                );
            });
        }
    }
}
