export default function ($state, apiService, restaurant, $timeout, statePromise, availabilityService, menus) {
    'ngInject';

    const vm = this;
    vm.consumptionModes = availabilityService;
    vm.menus = menus;

    if ($state.is('home.availability')) {
        // eslint-disable-next-line no-inner-declarations
        $state.go('home.availability.menu', {
            menus: vm.menus,
            menuId: vm.menus[0].menuId,
        });
    }

    vm.searchValue = '';

    vm.filterBySearch = function () {
        vm.consumptionModes.searchFilter = vm.searchValue;
    };

    vm.filterByConsumptionModes = function () {
        statePromise.go(
            'home.availability.modal_filters',
            {},
            {
                location: false,
            },
            'dataFromModal',
        );
    };

    vm.reloadKiosks = function () {
        apiService.reloadKiosk
            .post({
                restaurantId: restaurant.restaurantId,
            })
            .$promise.then(function () {
                statePromise.go(
                    'home.availability.modal_reload_kiosks',
                    {},
                    {
                        location: false,
                    },
                    'reloadKiosks',
                );
            });
    };
}
